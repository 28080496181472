import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="name-title">Ramyar Daneshgar</h1>
        <p className="title-subtext">Software & Security Engineer</p>
        
        
        <p className="viterbi-subtext" style={{ fontSize: '0.8rem', color: '#666' }}>
          University of Southern California Viterbi School of Engineering
        </p>
        <button className="contact-button" onClick={() => window.location.href = 'mailto:ramyarda@usc.edu'}>
          Contact Me
        </button>
      </header>
    </div>
  );
}

export default App;
